import React from "react";
import "../privacy-policy.css";

export default function CaliforniaPrivacyText() {
  return (
    <div>
      <div className="w-5/6 md:w-3/5 mx-auto mb-24 md:mb-48 mt-12 md:mt-20">
        <h1>PRIVACY NOTICE FOR CALIFORNIA RESIDENTS</h1>
        <p>Effective Date: January 1, 2020 </p>
        <p>
          This Privacy Notice for California Residents supplements and is
          expressly made part of the information contained in the CalCon Mutual
          Mortgage LLC, Privacy Policy and applies solely to all visitors,
          users, and others who reside in the State of California (“consumers”
          or “you”). We adopt this Notice to comply with the California Consumer
          Privacy Act of 2018 (CCPA) and other applicable laws. Any terms
          defined in the CCPA have the same meaning when used in this Notice.
        </p>
        <h5>
          <strong>Information We Collect</strong>
        </h5>
        <p>
          Our Websites may collect information that identifies, relates to,
          describes, references, is capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular
          consumer or device (“personal information”). In particular, our
          Websites may have collected the following categories of personal
          information from consumers since January 1, 2020, the effective date
          of the CCPA:
        </p>
        <img
          className="alignnone wp-image-273 size-large mx-auto"
          src="https://onetrusthomeloans.com/wp-content/uploads/2023/01/CCPA-Categories-of-Information-836x1024.jpg"
          alt=""
          width="800"
        />
        &nbsp; <p>Personal information does not include:</p>
        <ul>
          <li class="a">
            Publicly available information from government records.
          </li>
          <li class="a">De-identified or aggregated consumer information.</li>
          <li class="a">
            Information excluded from the CCPA’s scope, like:
            <ul>
              <li class="a">
                Health or medical information covered by the Health Insurance
                Portability and Accountability Act of 1996 (HIPAA) and the
                California Confidentiality of Medical Information Act (CMIA) or
                clinical trial data;
              </li>
              <li class="a">
                Personal information covered by certain sector-specific privacy
                laws, including the Fair Credit Reporting Act (FCRA), the
                Gramm-Leach-Bliley Act (GLBA) or California Financial
                Information Privacy Act (FIPA), and the Driver’s Privacy
                Protection Act of 1994.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          CalCon Mutual Mortgage obtains the categories of personal information
          listed above from the following categories of sources:
        </p>
        <ul>
          <li class="a">
            Directly from you. For example, from forms you complete or products
            and services you purchase.
          </li>
          <li class="a">
            Indirectly from you. For example, from observing your actions on our
            Websites.
          </li>
          <li class="a">
            Records and copies of your correspondence (including email
            addresses), if you contact us.
          </li>
          <li class="a">
            Your responses to surveys that we might ask you to complete for
            research purposes.
          </li>
          <li class="a">
            From third-party business partners such as social media sites, ad
            networks, and analytics providers.
          </li>
        </ul>
        &nbsp;
        <h5>
          <strong>Use of Personal Information</strong>
        </h5>
        <p>
          We use information to offer products and services to you, fulfill your
          requests, improve the quality of our products and services, engage in
          research and analysis relating to our products and services,
          personalize your experience, track usage of our Website, market our
          products and services, provide customer support, message you, back up
          our systems, allow for disaster recovery, enhance the security of our
          Website, and comply with legal obligations. Even when we do not retain
          such information, it still must be transmitted to our servers
          initially and stored long enough to process. We also use information
          that we collect about you or that you provide to us, including any
          Personal information:
        </p>
        <ul>
          <li class="a">
            To present the content of our products and services to you.
          </li>
          <li class="a">
            To provide you with information, products, or services that you
            request from us.
          </li>
          <li class="a">
            To fulfill any other purpose for which you provide it.
          </li>
          <li class="a">
            To provide you with notices about your account, including expiration
            and renewal notices.
          </li>
          <li class="a">
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li class="a">
            To notify you about changes to our products or services we offer or
            provide though it.
          </li>
          <li class="a">
            In any other way we may describe when you provide the information.
          </li>
          <li class="a">For any other purpose with your consent.</li>
        </ul>
        <p>
          We may use or disclose the personal information we collect for one or
          more of the following business purposes:
        </p>
        <ul>
          <li class="a">
            To fulfill or meet the reason you provided the information. For
            example, if you share your name and contact information to request a
            consultation or ask a question about our products or services, we
            will use that personal information to respond to your inquiry. If
            you provide your personal information to purchase a product or
            service, we will use that information to process your payment and
            facilitate delivery. We may also save your information to facilitate
            new product orders.
          </li>
          <li class="a">
            To provide, support, personalize, and develop our Websites,
            products, and services.
          </li>
          <li class="a">
            To create, maintain, customize, and secure your account with us.
          </li>
          <li class="a">
            To process your requests, purchases, transactions, and payments and
            prevent transactional fraud.
          </li>
          <li class="a">
            To provide you with support and to respond to your inquiries,
            including to investigate and address your concerns and monitor and
            improve our responses.
          </li>
          <li class="a">
            To personalize your Website experience and to deliver content and
            product and service offerings relevant to your interests, including
            targeted offers and ads through our Websites, third-party sites, and
            via email or text message (with your consent, where required by
            law).
          </li>
          <li class="a">
            To help maintain the safety, security, and integrity of our
            Websites, products and services, databases and other technology
            assets, and business.
          </li>
          <li class="a">
            For testing, research, analysis, and product development, including
            to develop and improve our Websites, products, and services.
          </li>
          <li class="a">
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </li>
          <li class="a">
            As described to you when collecting your personal information or as
            otherwise set forth in the CCPA.
          </li>
          <li class="a">
            • To evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of CalCon Mutual Mortgage’s assets, whether as a going concern
            or as part of bankruptcy, liquidation, or similar proceeding, in
            which personal information held by CalCon Mutual Mortgage about our
            Website users is among the assets transferred.
          </li>
        </ul>
        <p>
          CalCon Mutual Mortgage will not collect additional categories of
          personal information or use the personal information we collected for
          materially different, unrelated, or incompatible purposes without
          providing you notice.
        </p>
        <h5>
          <strong>Sharing Personal Information</strong>
        </h5>
        <p>
          CalCon Mutual Mortgage may disclose your personal information to a
          third party for a business purpose. When we disclose personal
          information for a business purpose, we enter a contract that describes
          the purpose and requires the recipient to both keep that personal
          information confidential and not use it for any purpose except
          performing the contract.
        </p>
        <p>
          We share your personal information with the following categories of
          third parties:
        </p>
        <ul>
          <li class="a">Subsidiaries and affiliates.</li>
          <li class="a">Service providers.</li>
          <li class="a">
            Third parties with whom we partner with to offer products and
            services to you.
          </li>
          <li class="a">Data aggregators.</li>
        </ul>
        <p className="underline">
          Disclosures of Personal Information for a Business Purpose
        </p>
        <p>
          Since January 1, 2020, CalCon Mutual Mortgage has disclosed the
          following categories of personal information for a business purpose:
        </p>
        <ul>
          <li class="a">Category A: Identifiers.</li>
          <li class="a">
            Category B: California Customer Records personal information
            categories.
          </li>
          <li class="a">
            Category C: Protected classification characteristics under
            California or federal law.
          </li>
          <li class="a">Category E: Commercial information.</li>
          <li class="a">
            Category G: Internet or other similar network activity.
          </li>
          <li class="a">Category H: Geolocation data.</li>
          <li class="a">
            Category J: Current or past job history or performance evaluations.
          </li>
        </ul>
        &nbsp;
        <h5>
          <strong>Sales of Personal Information</strong>
        </h5>
        <p>We Do not Sell Your Information:</p>
        <p>
          CalCon Mutual Mortgage does not sell personal information. We do not
          sell or otherwise disclose personal information we collect on our
          Sites, except as described here. We may share the information with our
          service providers who perform services on our behalf. These service
          providers are not authorized by us to use or disclose the information
          except as necessary to perform services on our behalf or comply with
          legal requirements. We may also provide personal information to third
          parties such as technology providers to enable such third parties to
          perform business, professional and technical support functions for us,
          our Sites or for you. On certain Sites, we provide tools to allow real
          estate agents to communicate with each other to negotiate
          transactions. In the course of these communications, they may use our
          tools to share personal information about the parties they represent.
        </p>
        <p>
          CalCon Mutual Mortgage can and does disclose your personal information
          to third-parties for a business purpose. When we disclose personal
          information to a non-governmental entity, we enter a contract that
          describes the purposes and requires the recipients to both keep that
          personal information confidential and not use it for any purpose
          except for the performance of the contract. We may disclose your
          personal information to the following categories of third-parties: (a)
          our affiliates; (b) vendors; (c) third-parties whom you or your agent
          has authorized to receive your personal information; (d) third-parties
          who provide marketing services for us; and (e) regulatory agencies,
          courts and other authorities who have requested your personal
          information pursuant to applicable law.
        </p>{" "}
        <p>
          In the preceding 12-month period, we have disclosed, for a business
          purpose, the following categories of personal information to
          third-parties: (a) Personal Identifiers; (b) Personal Information
          Categories in the California Customer Records Statute; (c) Protected
          Classification Characteristics Under California and/or Federal Law;
          (d) Employment-Related Information; and (e) Internet Activity.
        </p>
        <h5>
          <strong>Your California Privacy Rights</strong>
        </h5>
        <p>
          We do not share personal information (as defined by California Civil
          Code § 1798.83) with other people or non-affiliated businesses for
          their direct marketing purposes. Our websites do not respond to
          browser do-not-track signals. Under the California Consumer Privacy
          Act of 2018 (“CCPA”), California consumers have the right to request:
        </p>
        <ul>
          <li>The deletion of the personal information we have about them.</li>
          <li>
            Additional information about whether and how we have collected,
            used, disclosed, and sold personal information about them.
          </li>
          <li>
            The specific pieces of personal information we have about them.
          </li>
          <li>The correction of personal information</li>
        </ul>
        <p>
          California consumers also have the right not to receive discriminatory
          treatment if they exercise the rights listed above.{" "}
        </p>
        <p>
          When you make a request, we may require that you provide information
          and follow procedures so that we can verify the request and your
          jurisdiction before responding to it. The verification steps we take
          may differ depending on the request you make. We will match the
          information that you provide in your request to information we already
          have on file to verify your identity. If we are able to verify your
          request, we will process it. If we cannot verify your request, we may
          ask you for additional information to help us verify your request.{" "}
        </p>
        <p>
          Consumers will be required to submit their first and last name and
          email address and may also be asked to provide their telephone number
          or address so that we can verify the request. Please provide as much
          of the requested information as possible to help us verify the
          request. We will only use the information received in a request for
          the purposes of responding to the request.{" "}
        </p>
        <p>
          California law permits California consumers to use an authorized agent
          to make privacy rights requests. We require the authorized agent to
          provide us with proof of the California consumer’s written permission
          (for example, a power of attorney) that shows the authorized agent has
          the authority to submit a request for the California consumer. An
          authorized agent must follow the process described below to make a
          request. The authorized agent must also verify his/her own identity.
          We will confirm the agent’s authority with the California consumer
          about whom the request was made.{" "}
        </p>
        <p>You may submit a privacy rights request to us by:</p>
        <ul>
          <li>
            Visiting our Data Subject Access Request (“DSAR”) portal{" "}
            <strong>
              <a
                className="underline text-blue-primary"
                href="https://privacyportal.onetrust.com/webform/4a04f028-13c4-4be6-8904-7e62d05bd127/b4c75a08-d6ab-4fcf-886c-2196fa13e00c"
              >
                here
              </a>
            </strong>
            .
          </li>
          <li>Calling us at 888-974-0646</li>
        </ul>
        &nbsp;
        <h5>
          <strong>Changes to Our Privacy Notice</strong>
        </h5>
        <p>
          CalCon Mutual Mortgage reserves the right to amend this privacy Notice
          at our discretion and at any time. When we make changes to this
          privacy Notice, we will post the updated Notice on the Websites and
          update the Notice’s effective date. Your continued use of our Websites
          following the posting of changes constitutes your acceptance of such
          changes.
        </p>
        <h5>
          <strong>Contact Information</strong>
        </h5>
        <p>
          If you have any questions or comments about this Notice, the ways in
          which CalCon Mutual Mortgage collects and uses your information
          described above, your choices and rights regarding such use, or wish
          to exercise your rights under California law, please do not hesitate
          to contact us at:
        </p>
        <p>Phone: 888-974-0646 </p>
        <p>Email: compliance@calconmutualmortgage.com</p>
        <p className="pb-2 pt-10">Sending a letter to us at: </p>
        <p className="pb-0 mb-0">CalCon Mutual Mortgage</p>
        <p className="pb-0 pt-0">Attn: Compliance Officer</p>{" "}
        <p className="pb-0 pt-0">3838 Camino Del Rio North. Suite 305</p>
        <p className="pt-0 pb-10">San Diego, CA 92108 &nbsp;</p>
        <p>
          DISCLOSURE FOR CALIFORNIA USERS: GRA AND OTHER PARTIES NOT AFFILIATED
          WITH US MAY COLLECT PERSONALLY IDENTIFIABLE INFORMATION ABOUT YOUR
          ONLINE ACTIVITIES OVER TIME AND ACROSS DIFFERENT WEBSITES WHEN YOU USE
          OUR WEBSITE OR THE SERVICES OFFERED ON OUR WEBSITE. WE DO NOT HAVE ANY
          PROTOCOL THAT OFFERS YOU THE CHOICE TO OPT-OUT OF INTERNET TRACKING.
          AT PRESENT TIME, THE GRA WEBSITE IS UNABLE TO RESPOND TO “DO NOT
          TRACK” SIGNALS FROM YOUR INTERNET BROWSER. IF YOU HAVE ANY QUESTIONS
          REGARDING OUR INTERNET TRACKING METHODS PLEASE EMAIL US AT
          <span class="underline">COMPLIANCE@CALCONMUTUALMORTGAGE.COM</span>.
        </p>
      </div>
    </div>
  );
}
