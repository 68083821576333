import React, { useEffect } from "react";
import Footer from "../components/footer";
import PrivacyPolicyText from "./PrivacyPolicyText";

export default function PrivacyPolicy() {
  // create a useEffect that scrolls the wind to the top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PrivacyPolicyText />
      <Footer />
    </div>
  );
}
