import React from "react";
import Banner from "../components/banner";
import Faq from "../components/faq";
import Footer from "../components/footer";

export default function HomePage() {
  return (
    <div>
      <Banner />
      <Faq />
      <Footer />
    </div>
  );
}
