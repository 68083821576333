import React, { useEffect } from "react";
import Footer from "../components/footer";
import TextOptInForm from "./TextOptInForm";

export default function TextPolicy() {
  // scroll to top when page is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="w-5/6 md:w-4/6 mx-auto mt-12 py-20 text-center md:text-left">
        <h1 className="text-center md:text-left text-7xl text-blue-primary  font-semibold">
          Contact A Home Loan Expert
        </h1>
      </div>
      <TextOptInForm />
      <Footer />
    </div>
  );
}
