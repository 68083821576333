import React from 'react';

const Faq = () => (
    <div className="pt-10">
        <div className="py-3 md:py-20 mt-14 md:mt-20">
            <h3 className="text-22 text-center text-blue-primary font-medium mb-0 sm:text-30 lg:text-40">FAQs</h3>
        </div>

        <div className="container">
            <div className="faq-question-wrapper mb-16 border-gray-satan border-b md:pb-8">
                <h2 className="faq-question flex justify-between text-18 mb-8 md:text-25 md:w-full xl:text-26 xl:pr-48">How do I know where to make my mortgage payment?</h2>
                <p className="text-15 font-light mb-6 md:block md:text-20 md:mb-0 xl:text-21">If you’re making mortgage payments to CalCon Mutual Mortgage, you will have received an email from us with instructions
                on how to make your payment, your payment amount, payment due date, and payment method options. Contact us if you
                have any questions related to making your first mortgage payment.</p>
            </div>

            <div className="faq-question-wrapper mb-16 border-gray-satan border-b md:pb-8">
                <h2 className="faq-question flex justify-between text-18 mb-8 md:text-25 md:w-full xl:text-26 xl:pr-48">How do I make my mortgage payment?</h2>
                <p className="text-15 font-light mb-6 md:block md:text-20 md:mb-0 xl:text-21">If you have already registered to make online payments, click the Sign In button above to access your payment portal. If you have not yet registered, click the Register button above to start making your mortgage payments online.</p>
            </div>

            <div className="faq-question-wrapper mb-16 border-gray-satan border-b md:pb-8">
                <h2 className="faq-question flex justify-between text-18 mb-8 md:text-25 md:w-full xl:text-26 xl:pr-48">I received a transfer of servicing notice. What does this mean?</h2>
                <p className="text-15 font-light mb-6 md:block md:text-20 md:mb-0 xl:text-21">Just about all mortgages are moved into UMBS (Uniform Mortgage Backed Securities). When these loans are moved
                into the securities, CalCon Mutual Mortgage may transfer the servicing to another servicer. If the servicing is transferred, we’ll mail you a notice of the
                transfer of servicing, which will also include information on how to make your payment.</p>
            </div>

            <div className="faq-question-wrapper mb-16 border-gray-satan border-b md:pb-8">
                <h2 className="faq-question flex justify-between text-18 mb-8 md:text-25 md:w-full xl:text-26 xl:pr-48">How can I confirm the legitimacy of my transfer of servicing notice?</h2>
                <p className="text-15 font-light mb-6 md:block md:text-20 md:mb-0 xl:text-21">If you have already registered to make online payments, click the Sign In button above to access your payment portal. If
                you have not yet registered, use the Find My Loan feature above to point you in the right direction.
                </p>
            </div>
        </div>
    </div>



)

export default Faq;