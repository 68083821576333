import React, { useEffect } from "react";
import Footer from "../components/footer";

export default function SMSTermsOfUse() {
  // scroll to top when page is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="w-5/6 md:w-4/6 mx-auto mb-36 mt-12 py-20 text-center md:text-left">
        <h1 className="text-center md:text-left text-7xl text-blue-primary pb-24 font-semibold">
          SMS Terms of Use
        </h1>
        <p className="text-3xl leading-10 pb-4">
          By providing my telephone contact information I have authorized CalCon
          Mutual Mortgage LLC NMLS #46375, to contact me using an automated
          dialing system, even if the telephone number is a cellular phone or
          other service for which the party is charged. I understand that I am
          not required to provide this consent in order to obtain goods or
          services from CalCon Mutual Mortgage LLC. I agree to receive
          disclosures and communications in electronic form and I confirm that I
          have a working computer system and an internet website browser to view
          this webpage and this loan inquiry form. I agree that CalCon Mutual
          Mortgage LLC may contact me by mail, telephone, SMS, or email in
          connection with my request even if my telephone number or email
          address appears on a CalCon Mutual Mortgage LLC internal Do Not Call /
          Do Not Email List, a State or National Do Not Call Registry, a Do Not
          E-Mail list, or any other Do Not Contact List.
        </p>
        <p className="text-3xl leading-10 pb-4">
          For all SMS Communications Reply STOP to cancel. Message & Data Rates
          May Apply. Alerts sent via SMS may not be delivered if the mobile
          phone is not in range of a transmission site, or if sufficient network
          capacity is not available at a particular time. Even within a coverage
          area, factors beyond the control of the wireless carrier may interfere
          with message delivery, including the customer’s equipment, terrain,
          proximity to buildings, foliage, and weather. The wireless carrier
          does not guarantee that alerts will be delivered and will not be
          liable for delayed or undelivered messages. The act of opting in to
          receive SMS Communications from us shall not be construed as
          extending, conveying, or implying the grant of such consent to any
          third parties.
        </p>
        <p className="text-3xl leading-10 pb-4">
          To discontinue receiving SMS messages from CalCon Mutual Mortgage LLC
          NMLS #46375, reply with the text STOP.
        </p>
        <p className="text-3xl leading-10 pb-4">
          SMS Opt-In or phone numbers for the purpose of SMS are not being
          shared. CalCon Mutual Mortgage LLC respects your right to privacy. You
          can view our privacy policy{" "}
          <a
            href="https://www.calconmutualmortgage.com/privacy-policy"
            className="text-blue-primary underline"
          >
            here
          </a>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
}
