import React from "react";
import "../privacy-policy.css";
export default function PrivacyPolicyText() {
  return (
    <div>
      <div className="w-5/6 md:w-3/5 mx-auto mb-24 md:mb-48 mt-12 md:mt-20">
        <h1>Privacy Policy</h1>
        <p>
          {" "}
          CalCon Mutual Mortgage 1 (the “Company”) understands the importance of
          our customers’ privacy and therefore created this Privacy Policy to
          protect your personal information. This Privacy Policy covers the
          handling of any personal information we collect. There are also
          federal and state laws to protect the privacy of personal information.
        </p>
        <h2>Why Information Is Collected?</h2>
        <p>
          Financial companies choose how they share your personal information.
          Federal law and state law, as applicable, give consumers the right to
          limit some but not all sharing. Federal law and state law, as
          applicable, also requires us to tell you how we collect, share, and
          protect your personal information. Please read this notice carefully
          to understand what we do.
        </p>
        <h2>What Information Is Collected?</h2>
        <p>
          The types of personal information we collect and share depend on the
          product or service you have with us. This information can include:
          Social Security number, Credit history, Account balances, Credit
          scores, Payment history, and Employment information.
        </p>
        <h2>How Your Information Is Used</h2>
        <p>
          All financial companies need to share Customers personal information
          to run their everyday business. In the section below, we list the
          reasons financial companies can share their Customers personal
          information; the reasons CalCon Mutual Mortgage chooses to share; and
          whether you can limit this sharing.
        </p>
        <table>
          <thead>
            <tr>
              <td>
                <strong>Reasons we can share your personal information</strong>
              </td>
              <td>
                <strong>Does CalCon Mutual Mortgage Share?</strong>
              </td>
              <td>
                <strong>Can you limit this sharing?</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>For our everyday business purposes —</strong>
                such as to process your transactions, maintain your account(s),
                respond to court orders and legal investigations, or report ot
                credit bureaus
              </td>
              <td>
                <strong>Yes</strong>
              </td>
              <td>
                <strong>No</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>For our marketing purposes —</strong>
                to offer our products and services to you
              </td>
              <td>
                <strong>Yes</strong>
              </td>
              <td>
                <strong>No</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  For joint marketing with other financial companies
                </strong>
              </td>
              <td>
                <strong>No</strong>
              </td>
              <td>
                <strong>We Don’t Share</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  For our affiliates’ everyday business purposes —
                </strong>
                information about your transactions and experiences
              </td>
              <td>
                <strong>No</strong>
              </td>
              <td>
                <strong>We Don’t Share</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  For our affiliates’ everyday business purposes —
                </strong>
                information about your creditworthiness
              </td>
              <td>
                <strong>No</strong>
              </td>
              <td>
                <strong>We Don’t Share</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>For our affiliates to market to you</strong>
              </td>
              <td>
                <strong>Yes</strong>
              </td>
              <td>
                <strong>Yes</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>For nonaffiliates to market to you</strong>
              </td>
              <td>
                <strong>No</strong>
              </td>
              <td>
                <strong>We Don’t Share</strong>
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
        <h2>To Limit Our Sharing</h2>
        <p>
          If you are a customer or former customer of CalCon Mutual Mortgage and
          you wish to limit our sharing of your information for our affiliates
          to market to you, complete the form{" "}
          <a
            target={"_blank"}
            className="underline text-blue-primary"
            href="https://privacyportal.onetrust.com/webform/4a04f028-13c4-4be6-8904-7e62d05bd127/fa2811a3-6abc-4dec-bd1f-0c17d674da4e"
          >
            here
          </a>
          .
        </p>
        <p>
          Please note: If you are a new customer, we can begin sharing your
          information 30 days from the date we send you a privacy notice under
          applicable law. When you are no longer our customer, we continue to
          share your information as described in this policy and the applicable
          notice. However, you can contact us at any time to limit our sharing.
        </p>
        <h2>Questions?</h2>
        <p>
          Call 888-488-3807 or go to{" "}
          <a
            className="underline text-blue-primary"
            href="https://calconmutualmortgage.com"
          >
            https://calconmutualmortgage.com
          </a>
        </p>
        <h2>How CalCon Mutual Mortgage Protects Your Information.</h2>
        <p>
          To protect your personal information from unauthorized access and use,
          we use security measures that comply with federal law. These measures
          include computer safeguards and secured files and buildings.
        </p>
        <h2>
          How Does CalCon Mutual Mortgage Collect My Personal Information?
        </h2>
        <p>We collect your personal information, for example, when you</p>
        <ul>
          <li>Apply for a loan</li>
          <li>Pay your bills</li>
          <li>Show your government issued ID</li>
          <li>Use your credit or debit card</li>
          <li>Deposit Money</li>
        </ul>
        <p>
          We also collect your personal information from others, such as credit
          bureaus, affiliates, or other companies.
        </p>
        <h2>Why Can’t I Limit All Sharing?</h2>
        <p>
          {" "}
          Federal law gives you the right to limit only the sharing of
          information for affiliates’ everyday business purposes (i.e.,
          information about your creditworthiness), to limit affiliates from
          using your information to market to you, and to limit sharing your
          information for nonaffiliates to market to you. State laws and
          individual companies may give you additional rights to limit sharing.
          See below for more on your rights under state law.
        </p>
        <h2>
          What happens when I limit sharing for an account I hold jointly with
          someone else?
        </h2>
        <p>Your choices will apply to everyone on your account.</p>
        <h2>Definitions</h2>
        <table width="1156" style={{ height: "268px" }}>
          <tbody>
            <tr>
              <td>Affiliates</td>
              <td>
                Companies related by common ownership or control. They can be
                financial and nonfinancial companies.
                <ul>
                  • Our affiliates include financial companies engaged in the
                  business of originating and servicing consumer mortgage loans,
                  offering insurance products, and other related activities.
                </ul>
              </td>
            </tr>
            <tr>
              <td>Nonaffiliates</td>
              <td>
                Companies not related by common ownership or control. They can
                be financial and nonfinancial companies.
                <ul>
                  • CalCon Mutual Mortgage does not share with nonaffiliates so
                  they can market to you.
                </ul>
              </td>
            </tr>
            <tr>
              <td>Joint Marketing</td>
              <td>
                A formal agreement between nonaffiliated financial companies
                that together market financial products and services to you.
                <ul>• CalCon Mutual Mortgage does not jointly market.</ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <h2>Your California Privacy Rights</h2>
        <p>
          For more information about your California Privacy rights, please
          visit our Privacy Notice For California Residents{" "}
          <a
            className="underline text-blue-primary"
            href="/privacy-notice-for-california-residents"
          >
            here
          </a>
          .
        </p>
        <p>
          - For California Residents: California residents are provided a
          separate notice Privacy Notice with additional choices as required by
          California law.
        </p>
        <h2>Rights Under Other State Laws</h2>
        <p>
          - For Nevada Residents: We are providing this notice under Nevada law
          (Nev. Rev. Stat. § 228.600(3)). You may be placed on our Internal Do
          Not Call list by contacting us via the information set forth at the
          end of this Policy. Nevada law requires that we also provide you with
          the following contact information: Bureau of Consumer Protection,
          Office of the Nevada Attorney General, 555 E. Washington St., Suite
          3900, Las Vegas, NV 89101; Phone number: (702) 486-3132; email:
          BCPINFO@ag.state.nv.us.{" "}
        </p>
        <p>
          {" "}
          - For Vermont Residents: We will not disclose information about your
          creditworthiness to our affiliates and will not disclose your personal
          information, financial information, credit report, or health
          information to nonaffiliated third parties to market to you, other
          than as permitted by Vermont law, unless you authorize us to make
          those disclosures.
        </p>
        <h2>SMS Terms of Use</h2>
        <p>
          Please find a link to our SMS Terms of Use{" "}
          <a
            className="underline text-blue-primary"
            href="https://www.calconmutualmortgage.com/terms-of-use/"
          >
            here
          </a>
          . SMS Opt-In or phone numbers for the purpose of SMS are not being
          shared.
        </p>
        <h2>Third-Party Sites</h2>
        <p>
          The Company’s website may occasionally contain links to other sites.
          Please be aware that we are not responsible for the content or privacy
          practices of such other sites. We encourage our users to be aware when
          they leave our site, they should read the privacy statements of any
          other site that collects personally identifiable information.
        </p>
        <h2>Cookies</h2>
        <p>
          CalCon Mutual Mortgage and our third-party service providers may use
          cookies and similar technologies to support the operation of CalCon
          Mutual Mortgage’s websites. Cookies are small amounts of data that a
          website exchanges with a web browser or application on a visitor’s
          computer or mobile device. Cookies help us to collect information
          about visitors to CalCon Mutual Mortgage’s websites, including date
          and time of visits, pages viewed, amount of time spent on our sites,
          or general information about the device used to access the site.{" "}
        </p>
        <p>
          CalCon Mutual Mortgage also uses targeting cookies. These cookies may
          be set through our site by our advertising partners. They may be used
          by those companies to build a profile of your interests and show you
          relevant adverts on other sites. They do not store directly personal
          information, but are based on uniquely identifying your browser and
          internet device. If you do not allow these cookies, you will
          experience less targeted advertising.
        </p>{" "}
        <p>
          The third-party vendors, including Google, whose services we use, will
          place cookies on web browsers in order to serve ads based on past
          visits to our website. Third party vendors, including Google, use
          cookies to serve ads based on a user’s prior visits to your website.
          This allows us to make special offers and continue to market our
          services to those who have shown interest in our service.
        </p>{" "}
        <p>
          You can refuse or delete cookies. Most browsers and mobile devices
          offer their own settings to manage cookies. If you refuse a cookie
          when on an CalCon Mutual Mortgage website, or if you delete cookies,
          you may experience some inconvenience in your use of our websites. For
          example, you may not be able to sign in and access your account, or we
          may not be able to recognize you, your device, or your online
          preferences.
        </p>
        <h2>Connecting With CalCon Mutual Mortgage On Social Media Sites</h2>
        <p>
          CalCon Mutual Mortgage provides experiences on a variety of social
          media platforms that enable online sharing and collaboration among
          users who have registered to use them. We may collect information you
          provide by interacting with us via social media, such as photographs,
          opinions, or Twitter handle. Any content you post, such as pictures,
          information, opinions, or any personal information that you make
          available to other participants on these social platforms is
          considered public information and is subject to the terms of use and
          privacy policies of those platforms. Please refer to each social media
          platform to better understand your rights and obligations with regard
          to such content.
        </p>
        <h2>Contests And Surveys</h2>
        <p>
          From time to time, we may offer you the option to participate in
          contests, sweepstakes, or surveys. If you would like to participate,
          we may ask you for contact information, preferences, or other
          information. This information may be used to conduct research, improve
          our offerings, or contact you regarding home financing or award
          prizes. Our contests or sweepstakes may have separate rules, and we
          will identify how the information that you supply will be used in each
          case.
        </p>
        <h2>Changes To This Privacy Policy</h2>
        <p>
          The Company reserves the right to amend this policy at any time as
          deemed necessary. To ensure you are updated with changes, please
          continue to visit this privacy page regularly.
        </p>{" "}
        <p>
          CalCon Mutual Mortgage is a Delaware LLC (NMLS #46375) headquartered
          at 3838 Camino del Rio N Suite 305, San Diego, CA 92108 (phone:
          888-488-3807). &nbsp;
        </p>
      </div>
    </div>
  );
}
