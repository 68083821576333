import React from "react";
import BannerImage from "../assets/computer-banner.jpg";

const Banner = () => (
  <div
    className="h-160 flex flex-col md:flex-row background-image"
    style={{
      backgroundImage: `url(${BannerImage})`,
      height: "700px",
      backgroundSize: "cover",
    }}
  >
    <div className="hidden banner-text">
      <h1 className="text-white font-banner lg:text-75 mt-72 md:ml-10 lg:ml-48">
        <strong>Your reliable </strong>
        <br />
        lender and servicer
      </h1>
    </div>

    <div className="block md:hidden bg-blue-dark py-2">
      <h1 className="text-white text-19 text-center">
        Your reliable lender and servicer
      </h1>
    </div>

    <div className="md:ml-20 lg:ml-96 flex flex-col items-center mt-0 md:mt-10 md:mt-40 md:bg-white widget-container">
      <h2 className="text-black text-21 font-semibold mt-10 mb-10 md:mb-0">
        Visit our Mortgage Servicing Portal
      </h2>
      <h3 className="text-black text-19 md:text-17 text-center md:mt-20 mx-10">
        If you haven't already, please register to make a mortgage payment
        online.
      </h3>

      <div className="padding-button">
        <a
          className="button btn action-btn btn-lg mt-6 self-center register-btn"
          target="_blank"
          href="https://loansphereservicingdigital.bkiconnect.com/mycalconmutualmortgage/#/register"
          role="button"
        >
          Register
        </a>
      </div>

      <h3 className="text-black text-19 md:text-17 text-center mt-20 mx-10">
        Already Registered? Sign in to make a mortgage payment.
      </h3>

      <div className="padding-button">
        <a
          className="button btn action-btn btn-lg mt-6 self-center register-btn  md:mb-0"
          target="_blank"
          href="https://loansphereservicingdigital.bkiconnect.com/mycalconmutualmortgage/#/login"
          role="button"
        >
          Log In
        </a>
      </div>
    </div>
  </div>
);

export default Banner;
