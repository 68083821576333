import React, { useState, useEffect } from "react";
import Footer from "../components/footer";

export default function CookieInformatoin() {
  const [cookieChoice, setCookieChoice] = useState(true);
  console.log(cookieChoice);
  // scroll to top when page is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="w-5/6 md:w-4/6 mx-auto mb-36 mt-12 py-20 text-center md:text-left">
        <h1 className="text-center md:text-left text-7xl text-blue-primary pb-24 font-semibold">
          Cookie Information & Opt-Out
        </h1>
        <p className="text-3xl leading-10 pb-4">
          When you visit our website, we store cookies on your browser to
          collect information. We also share information about your use of our
          site with our social media, advertising and analytics partners. The
          information collected might relate to you, your preferences or your
          device, and is mostly used to make the site work as you expect it to
          and to provide a more personalized web experience. However, you can
          choose not to allow certain types of cookies, which may impact your
          experience of the site and the services we are able to offer. You
          cannot opt-out of our First Party Strictly Necessary Cookies as they
          are deployed in order to ensure the proper functioning of our website
          (such as prompting the cookie banner and remembering your settings, to
          log into your account, to redirect you when you log out, etc.).
        </p>
        <p>
          {" "}
          <b className="text-3xl leading-10 pb-4">Performance Cookies</b>
        </p>
        <p className="text-3xl leading-10 pb-4 mt-4">
          These cookies allow us to count visits and traffic sources so we can
          measure and improve the performance of our site. They help us to know
          which pages are the most and least popular and see how visitors move
          around the site. All information these cookies collect is aggregated
          and therefore anonymous. If you do not allow these cookies we will not
          know when you have visited our site, and will not be able to monitor
          its performance.
        </p>

        <div
          id="checkbox-container"
          className="flex inline items-center justify-center md:justify-start"
        >
          <input
            id="cookiechoice"
            className="cookiechoice"
            value={cookieChoice}
            onChange={() => setCookieChoice(!cookieChoice)}
            type="checkbox"
          />
          <p className="p-2 mt-2 text-2xl ">
            <i>
              <b>Opt in to performance cookies</b>
            </i>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
