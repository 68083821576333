import React, { useState } from "react";

const TextOptInForm = () => {
  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    textOptIn: false,
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialFormState);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "phone") {
      setFormData((prev) => ({
        ...prev,
        [name]: formatPhoneNumber(value),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "Please enter your first name";
    if (!formData.lastName.trim())
      newErrors.lastName = "Please enter your last name";
    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Please enter your phone number";
    } else if (formData.phone.replace(/[^\d]/g, "").length !== 10) {
      newErrors.phone = "Please enter a valid phone number";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      setIsSubmitted(true);
      setFormData(initialFormState);
    }, 2000);
  };

  return (
    <div className="max-w-5xl mx-auto p-6 mb-48 border rounded-lg shadow-lg">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-10 my-6">
          <div>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              className={`w-full p-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-primary ${
                errors.firstName ? "border-red-500" : ""
              }`}
            />
            {errors.firstName && (
              <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>
            )}
          </div>

          <div>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              className={`w-full p-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-primary ${
                errors.lastName ? "border-red-500" : ""
              }`}
            />
            {errors.lastName && (
              <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
            )}
          </div>

          <div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className={`w-full p-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-primary ${
                errors.email ? "border-red-500" : ""
              }`}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>

          <div>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone"
              maxLength="14"
              className={`w-full p-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-primary ${
                errors.phone ? "border-red-500" : ""
              }`}
            />
            {errors.phone && (
              <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
            )}
          </div>
        </div>

        <div className="flex items-center space-x-2 mb-6">
          <input
            type="checkbox"
            id="textOptIn"
            name="textOptIn"
            checked={formData.textOptIn}
            onChange={handleChange}
            className="h-8 w-8"
          />
          <label htmlFor="textOptIn" className="text-15 lg:pl-4">
            I consent to receive SMS texts from CalCon Mutual Mortgage about its
            services. Message and Data rates apply.
          </label>
        </div>

        <p className="text-12 text-gray-600 mb-6">
          By clicking the 'Submit' button below, I authorize CalCon Mutual
          Mortgage to contact me about a mortgage by a live agent, and
          artificial or prerecorded voice at my residential or cellular number,
          dialed manually or by autodialer and to be contacted via email, even
          if the telephone number is a cellular phone or other service for which
          the party is charged. I understand that I am not required to provide
          this consent in order to obtain goods or services from CalCon Mutual
          Mortgage. I agree to receive disclosures and communications in
          electronic form and I confirm that I have a working computer system
          and an internet website browser to view this webpage and this loan
          inquiry form. I agree that CalCon Mutual Mortgage may contact me by
          mail, telephone or email in connection with my request even if my
          telephone number of email address appears on a CalCon Mutual Mortgage
          internal Do Not Call / Do Not Email List, a State or National Do Not
          Call Registry, or Do Not E-Mail list, or any other Do Not Contact
          List.
        </p>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-1/3 flex mx-auto my-6 items-center justify-center text-center bg-blue-primary text-white p-4 rounded-md hover:bg-blue-complimentary"
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>

        {isSubmitted && (
          <p className="mt-4 text-center text-green-button">
            Thank you for your submission!
          </p>
        )}
      </form>
    </div>
  );
};

export default TextOptInForm;
