import React from "react";
import Footer from "./footer";
import CaliforniaPrivacyText from "./CaliforniaPrivacyText";

export default function CaliforniaPrivacyNotice() {
  return (
    <div>
      <CaliforniaPrivacyText />
      <Footer />
    </div>
  );
}
