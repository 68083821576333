import React from 'react';
import logo from '../../src/calcon-logo-white-2x.png';

const Header = () => (
    <nav className="header bg-blue-primary md:p-6">
        <div className="container md:px-4 mx-auto flex flex-col items-center md:flex-row md:items-center">
            <div className="flex justify-between items-center">
                <img src={logo} className="w-40 md:w-72 mt-3 md:mt-0" alt="CalCon Mutual Mortgage Logo" />
            </div>
            <div className="flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0" id="navbar-collapse">
                <a href="callto://8559953326" className="text-white px-2 md:p-2 lg:px-4 md:mx-2 font-medium text-11 md:text-18 hover:text-white hover:underline md:border-r" title="Call CalCon Mutual Mortgage">(855) 452-8803</a>
                <p className="text-white px-2 md:p-2 lg:px-4 md:mx-2 text-11 md:text-18 font-light hover:text-white">Hours: 8:30am - 8:00pm EST Monday - Friday, 9am - 3pm EST Saturday</p>
            </div>
        </div>
    </nav>
)

export default Header;